
body[data-layout-mode="dark"] {
    background-color: $gray-dark-200;
    color: $gray-dark-600;


    .h1,.h2,.h3,.h4,.h5,.h6,
    h1,h2,h3,h4,h5,h6 {
        color: $gray-dark-600;
    }
}
  
.navbar {
    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.05);
    padding: 1rem;
    border-bottom: 1px solid #eee;

    .navbar-brand {
        img {
        height: 30px;
    }
}

    .nav-link {
        font-size: 1rem;
        margin-right: 1.5rem;
        color: #333;
        &:hover {
        color: #555;
        }
        margin-top: 0.5rem;
        font-weight: 600;
    }
}

.hero-image{
    background-image: url("../../../images/base.png");
    height: 80%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.section{
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
}

.foot {
    // background-color: #0f1b42;
    // color: rgba(255, 255, 255, 0.5);
    // padding-top: 80px;
    // padding-bottom: 40px;
    padding: 2rem;
    box-shadow: 1rem 0.5rem 0rem rgba(0,0,0,0.05);
    border-top: 1px solid #eee;
    
    
}
.footer-list-title {
    font-size: 16px;
    color: #ffffff;
  }

  .pp-rel{
    position: relative;
    .pp-abs{
        position: absolute;
        top: 70px;
        left: 48px;
        background-color: #EAEAEF;
        border: 1.5px solid #FFFFFF;
        box-shadow: 0px 60px 60px rgba(29, 29, 31, 0.24);
        cursor: pointer;
    }
    .pp-spinner{
        position: absolute;
        top: 25px;
        left: 15px;
        cursor: pointer;
    }
    .upload-input{
        display: none;
    }
}

.special-label {
    display: none;
}

.pp-size{
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: #84868B;
}

.card-courses .img-fluid{
    max-width: 100%;
    height: 130px;
}

.paginationBttns {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    padding: 0;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin-right: 8px;
    border-radius: 5px;
    border: 1px solid #e9e9ef;
    color: grey;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    background-color: white;
    border: 1px solid #fff;
  }
  
  .paginationActive a {
    color: #fff !important;
    background-color: #5156be;
  }
  
  .paginationDisabled a {
    color: grey;
    background-color: #fff;
  }

  .paginationDisabled a:hover {
    color: grey;
    border: 1px solid #e9e9ef;
  }

  .preview-price{
    padding: 10px;  
    border: 2px solid red;
  }

  .embed-responsive-item{
    width: 100%;
    max-height: 100%;
  }

  .overflow-hidden{
    height: 165px !important;
  }

  // .embed-responsive-item {
  //   width: 100%;
  //   height: auto !important;
  // }
  
  @media (max-width: 768px) {
    .head .nav-head{
      margin-bottom: 5px;
    }
   }

   .placeholder {
    background-color: #c0c0c0 !important;
   }

  